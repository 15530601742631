.hero {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 300px; /* Set a specific height for the carousel */
}

.train-carousel {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.image-container {
  display: flex;
  animation: scrollTrain 20s linear infinite;
}

.image-container img {
  width: 12.5%; /* Adjust this value to fit your images */
  height: 100%;
  object-fit: cover;
}

@keyframes scrollTrain {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
