.myOrder {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f9fa;
  padding: 15px;
}

.order-container {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  width: 1200px;
 /* Updated to center the container */
  text-align: center;
}
.order-back {
  position: relative;
}

.order-back::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  opacity: 0.7; /* Adjust the opacity here */
  z-index: -1;
}


.order h1, .order h2, .order h3 {
  color: #e6be8a;
}

.order ul {
  list-style-type: none;
  padding: 0;
}

.order li {
  margin: 10px 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #e6be8a;
  border-radius: 5px;
}

.order-item {
  display: flex;
  align-items: center;
}

.order-item-image {
  width: 50px; /* Ensure all images have the same width */
  height: 50px; /* Ensure all images have the same height */
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #e6be8a;
  object-fit: cover; /* Ensure images maintain aspect ratio */
}

.order-item-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.rating-form {
  background-color: white;
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  border: 2px solid #e6be8a;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the content inside the form */
  margin: 0 auto; /* Center the form itself */
}

.rating-form h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #e6be8a;
}

.rating-form label {
  display: block;
  margin-bottom: 10px;
  color: #333;
  width: 100%; /* Ensure labels take full width */
}

.rating-form input,
.rating-form select,
.rating-form textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #e6be8a;
  border-radius: 5px;
  font-size: 1rem;
  background-color: white;
}

.submit-rating-btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #e6be8a;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.submit-rating-btn:hover {
  background-color: black;
}

.error {
  color: red;
}
