.video-cards-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    padding: 20px;
  }
  
  .video-section {
    flex: 1;
  }
  
  .promo-video {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .cards-section {
    flex: 1;
  }
  
  .cards-section h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .cards {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
  
  .card {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .card h3 {
    margin-top: 0;
    font-size: 20px;
  }
  
  .card p {
    margin: 10px 0 0;
    font-size: 16px;
  }
  
  .card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(255, 215, 0, 0.4);
  }
  
  .card:hover h3, .card:hover p {
    color: #b8860b; /* Darker golden color */
  }
  