



.order-container {
  max-width: 800px;
  margin: 100px auto;
  padding: 40px;
  border: 1px solid #e6be8a;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.order-container h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #e6be8a;
  font-size: 2.5em;
}


.order-container h2 {
  margin-bottom: 10px;
  color: #333333;
  font-size: 1.5em;
  border-bottom: 2px solid #e6be8a;
  padding-bottom: 5px;
}


.order-container p {
  margin-bottom: 10px;
  color: #666666;
  font-size: 1em;
}


.order-items {
  margin-top: 20px;
  margin-bottom: 20px;
}


.order-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e6be8a;
  transition: background-color 0.3s;
}


.order-item:last-child {
  border-bottom: none;
}


.order-item:hover {
  background-color: #f1e1c9;
}


.order-item img.order-item-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 20px;
}


.order-item-details h4 {
  margin: 0;
  font-size: 1.2em;
}


.order-item-details p {
  margin: 5px 0;
}


.proceed-to-checkout-btn {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: #e6be8a;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s;
  margin-top: 20px;
}


.proceed-to-checkout-btn:hover {
  background-color:black;
}


.rating-form {
  margin-top: 20px;
}


.rating-form label {
  display: block;
  margin-bottom: 10px;
}


.rating-form select,
.rating-form input,
.rating-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}


.submit-rating-btn,
.cancel-rating-btn {
  display: inline-block;
  width: calc(50% - 10px);
  padding: 10px;
  background-color: #e6be8a;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s;
  margin-top: 10px;
}


.submit-rating-btn:hover,
.cancel-rating-btn:hover {
  background-color:black;
}


.submit-rating-btn {
  margin-right: 20px;
  background-color:#e6be8a
}


.cancel-rating-btn {
  background-color:#e6be8a;
}


.error {
  color: red;
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
}


.loading {
  text-align: center;
  margin-top: 20px;
  font-size: 1.2em;
  color: #e6be8a;
}
/* Popup Container */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Popup Content */
.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 500px;
  text-align: center;
}

.popup-content h2 {
  margin-bottom: 20px;
}

.popup-content p {
  margin-bottom: 10px;
}

.popup-content button {
  background-color:  #e6be8a;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.popup-content button:hover {
  background-color:  black;
}


.add-address-btn, .update-address-btn, .proceed-to-checkout-btn 
{ padding: 10px 20px; 
  margin: 10px 0; border: none; 
  border-radius: 4px; 
  background-color:  #e6be8a; 
  color: #fff; 
  font-size: 16px; 
  cursor: pointer; 
  transition: background-color 0.3s ease; } 

.add-address-btn:hover, .update-address-btn:hover, .proceed-to-checkout-btn:hover 
{ background-color: black }
