.new-arrivals-container {
    width: 100%;
    padding-bottom: 16px;
  }
  
  .product-card {
    padding: 0 10px; /* Adjust padding as needed */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .product-image-container {
    width: 100%;
    height: 300px; /* Adjust height as needed */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .product-image {
    max-width: 80%;
    max-height: 80%;
    object-fit: cover;
  }
  
  .product-details {
    padding: 10px;
    text-align: center;
  }
  