.product-details-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #fff;
    padding: 20px;
    margin: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
  }
  
  .product-details-info {
    flex: 1;
    padding-right: 20px;
  }
  
  .product-details-info h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .product-details-info p {
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 10px;
  }
  
  .product-details-info .price {
    font-size: 1.5rem;
    color: #e6be8a;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .product-details-info .rating {
    font-size: 1.2rem;
    color: #e6be8a;
    margin-bottom: 20px;
  }
  
  .product-details-info .buttons {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }
  
  .product-details-info .buttons button {
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .product-details-info .buttons .back-button {
    background-color: #555;
    color: #fff;
  }
  
  .product-details-info .buttons .cart-button {
    background-color: #e6be8a;
    color: #fff;
  }
  
  .product-details-info .buttons button:hover {
    opacity: 0.8;
  }
  
  .product-details-image {
    flex-shrink: 0;
    width: 300px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
  
  .product-details-image img {
    width: 100%;
    height: 300px;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .product-details-image img:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .reviews { margin-top: 20px; }
 .review { border-top: 1px solid #ccc; padding-top: 10px; margin-top: 10px; }
  