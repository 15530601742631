/* Container for the entire page */
.container {
    display: flex;
    height: 100vh;
}

/* Left panel styling */
.left-panel {
    flex: 1;
    background-color: #F2CC9A;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.brand {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo {
    max-width: 100px;
}

h2 {
    margin: 20px 0;
}

h3 {
    margin-top: 20px;
}

.promo-image {
    margin-top: 20px;
    max-width: 80%;
}

/* Right panel styling */
.right-panel {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.form-container {
    background: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    max-width: 400px;
    width: 100%;
}

form {
    display: flex;
    flex-direction: column;
}

label {
    margin-bottom: 8px;
    font-weight: bold;
}

input, textarea {
    margin-bottom: 16px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: calc(100% - 24px); /* Ensure full width with padding accounted for */
}

button {
    padding: 12px;
    background-color: #F2CC9A;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

button:hover {
    background-color: #e0b07d;
}

.container {
  display: flex;
  height: 100vh;
  margin-top: 50px;
  margin-bottom: 50px;
}

.containerStyle {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.headerStyle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.subHeaderStyle {
  font-size: 14px;
  margin-bottom: 20px;
}

.contactItemStyle {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.iconStyle {
  margin-right: 10px;
  font-size: 24px;
}

.textStyle {
  font-size: 14px;
}

.loginContainerStyle {
  margin-top: 30px;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f9f9f9;
  text-align: center;
}
.loginHeaderStyle {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.loginTextStyle {
  font-size: 14px;
}
.contact-card {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.main-heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}
  
