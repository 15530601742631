/* Sidebar.css */
.sidebar {
  width: 200px;
  padding: 20px;
  border-right: 1px solid #ccc;
}

.sidebar h3 {
  margin-bottom: 20px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  margin-bottom: 10px;
}

.sidebar-actions {
  margin-top: 20px;
}

.action-button {
  display: flex;
  align-items: center;
  background-color: #e6be8a;
  border: none;
  color: white;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 5px;
  width: 100%; /* Set equal width for all buttons */
  box-sizing: border-box; /* Include padding in width calculation */
}

.action-button:hover {
  background-color: #d1a678;
}

.action-button svg {
  margin-right: 5px;
}
