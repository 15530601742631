.profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.profile-card {
  display: flex;
  flex-direction: column; /* Change this to column to stack elements vertically */
  width: 800px; /* Adjust the width as needed */
  margin: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-image-container {
  display: flex;
  flex-direction: column; /* Ensure content stacks vertically */
  align-items: center;
  padding: 20px;
}

.profile-info {
  flex: 1;
  padding: 20px;
}

.profile-actions {
  flex: 1;
  padding: 20px;
  width: 100%; /* Ensure the form takes full width */
}

.profile-icon {
  width: 200px;
  height: 200px;
  display: block;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
}

form label {
  margin-bottom: 10px;
}

form input {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%; /* Ensure the input takes full width */
}

.addresssub,
.addresscan {
  border: none;
  border-radius: 4px;
  background-color: #e6be8a;
  color: white;
  padding: 10px;
  margin-bottom: 10px; /* Add margin to separate buttons */
}

.addresssub:hover,
.addresscan:hover {
  background-color: rgb(158, 162, 201);
  color: white;
}

.he {
  color: #e6be8a;
  font-weight: bolder;
}
