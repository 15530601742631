/* Shop.css */
.shop-container {
  padding: 20px;
}

.shop-content {
  display: flex;
  flex-wrap: wrap;
  gap: 80px;
}

.shop-main {
  flex: 9;
}

.products {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start; /* Align products to the start of the container */
}

.product-container {
  position: relative;
  flex: 1 1 calc(25% - 20px); /* Adjust this width to fit four items per row with gaps */
  max-width: 220px; /* Set a maximum width for the product card */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  background-color: white;
  transition: transform 0.3s ease;
  animation: backgroundAnimation 5s ease-in-out infinite; /* Apply the animation */
}

/* @keyframes backgroundAnimation {
  0%, 100% {
    background-color: white;
  }
  50% {
    background-color: #e6be8a;
  }
} */

.product-container:hover {
  transform: scale(1.05);
}

.product-image-wrapper {
  position: relative;
  overflow: hidden;
}

.product-image {
  width: 100%;
  height: 180px; /* Adjust this height as needed */
  object-fit: cover; /* Ensure the image covers the area without distortion */
  transition: transform 0.3s ease;
  align-items: center;
  margin-left: 22px;
  margin-top: 5px;
}

.product-image-wrapper:hover .product-image {
  transform: scale(1.1);
}

.product-overlay {
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  transition: bottom 0.3s ease;
}

.product-image-wrapper:hover .product-overlay {
  bottom: 0;
}

.overlay-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.overlay-button:hover {
  color: #ffcc00; /* Change to desired hover color */
}

.product-details {
  padding: 10px;
  text-align: left;
  font-family: Arial, sans-serif;
  color: #333;
}

.product-details h2 {
  font-size: 1rem;
  font-weight: bold;
  margin: 10px 0;
}

.product-details p {
  font-size: 0.75rem;
  margin: 5px 0;
}

.product-details .price {
  font-size: 0.875rem;
  font-weight: bold;
  color: #e60023;
}

.product-details .rating {
  font-size: 0.75rem;
  color: #ffa41b;
}

.search-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.search-bar input[type="text"] {
  padding: 10px;
  margin-right: 10px;
  width: 600px; /* Updated width */
  border: 2px solid #e6be8a; /* Added border */
}


.search-bar button {
  padding: 10px;
  background-color: #e6be8a; /* Updated button color */
  margin-top: -15px;
  color: white;
  border: none;
  cursor: pointer;
  border: 2px solid #e6be8a;
}

.search-bar button:hover {
  background-color: #d7a768; /* Change hover color if needed */
}

.overlay-button:hover {
  background-color: #d1a16c;
}

.overlay-button.disabled,
.overlay-button.out-of-stock {
  background-color: grey;
  cursor: not-allowed;
}
