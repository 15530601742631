.product-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
  
  .product-card {
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
  }
  
  .product-image-container {
    position: relative;
    width: 100%;
    height: 200px; /* Adjust the height as needed */
    overflow: hidden;
  }
  
  .product-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the container */
    transition: transform 0.3s ease;
  }
  
  .product-image-container:hover .overlay {
    opacity: 1;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease;
    z-index: 1; /* Ensures the overlay is above the image */
  }
  
  .overlay-content {
    text-align: center;
  }
  
  .view-details-btn {
    background: none;
    border: none;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    padding: 10px 20px;
    border: 2px solid #fff;
    border-radius: 5px;
  }
  
  .product-details {
    padding: 10px;
  }
  
  .loading {
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
  }
  
  .best-seller-badge {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #ff6347; /* Tomato color */
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    z-index: 2; /* Ensures the badge is above the overlay and image */
  }
  
  .modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 3; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
    max-width: 600px;
    position: relative;
  }
  
  .close-btn {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close-btn:hover,
  .close-btn:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .modal-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .shop-btn {
    background-color: #4caf50; /* Green */
    color: white;
  }
  
  .close-modal-btn {
    background-color: #f44336; /* Red */
    color: white;
  }
  