/* Global styles */
.head {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Header styles */
/* Define styles for your header component */

/* Sidebar styles */
/* Define styles for your sidebar component */

/* Main content styles */
.shop-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
}

.shop-content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.shop-main {
  flex: 1;
}

/* Product styles */
.products {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.product-container {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.product-container:hover {
  transform: translateY(-5px);
}

.product-image-wrapper {
  position: relative;
  height: 180px;
  overflow: hidden;
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.product-image:hover {
  transform: scale(1.1);
}

.product-details {
  padding: 20px;
  background-color: #fff;
}

.product-details h2 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

.product-details p {
  margin: 5px 0;
  font-size: 0.9rem;
  color: #555;
}

.product-details p:first-child {
  font-weight: bold;
}

.product-details p:nth-child(2) {
  font-style: italic;
}

/* Search bar styles */
.search-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.search-bar input[type="text"] {
  padding: 10px;
  margin-right: 10px;
  width: 600px;
  border: 2px solid #e6be8a;
  border-radius: 5px;
  font-size: 1rem;
}

.search-bar button {
  padding: 10px 20px;
  margin-top: -15px;
  background-color: #e6be8a;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-bar button:hover {
  background-color: #d7a768;
}

/* Error message styles */
.error {
  color: red;
  font-size: 1rem;
  text-align: center;
  margin-bottom: 20px;
}
/* In your Inven.css file */
.product-image-wrapper {
  position: relative;
}

.stock-status {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
}

.out-of-stock {
  background-color: #ff0000; /* Red color for out of stock */
}

.in-stock {
  background-color: #00ff00; /* Green color for in stock */
}

.product-image {
  width: 100%;
  height: auto;
}
